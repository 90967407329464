(function($)
	{
	$('#cookie-notification').toggleClass("hidden");
	if ($.cookie('stylex')) $('#cookie-notification').toggleClass("hidden");
	else {
		$('#cookie-notification .hide').click(function() {
			$('#cookie-notification').toggleClass("hidden");
			var date = new Date();
			date.setTime(date.getTime() + (36000000 * 1000));
			$.cookie('stylex', true, { expires: date });
		});
	}
}(jQuery));