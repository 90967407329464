$(window).on('load resize', function(){
    if ($(window).width() <= 899){
        $('body').removeClass('desktop').addClass('mobile');
    } else {
        $('body').removeClass('mobile').addClass('desktop');
    }
}); 

$(document).ready(function(){

    $('li.mail a,a.mail').on('touch click', function(){
        $(this).attr("href", "mailto:info@reactive-media.de");
    });
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
    setTimeout(function() {
        $(".service .item:nth-child(7)").addClass("animated swing").addClass("grey");
    },10)

        $(".service .item:nth-child(6)").addClass("animated swing").addClass("grey");
    },30)

        $(".service .item:nth-child(5)").addClass("animated swing").addClass("grey");
    },50)

        $(".service .item:nth-child(4)").addClass("animated swing").addClass("grey");
    },70)

        $(".service .item:nth-child(3)").addClass("animated swing").addClass("grey");
    },90)

        $(".service .item:nth-child(2)").addClass("animated swing").addClass("grey");
    },110)

        $(".service .item:nth-child(1)").addClass("animated swing").addClass("grey");
    },130)

    },2500)
  



});